import { auth } from 'constants/api';
import client from '..';

export interface ITenantDetailsResponse {
  email: string;
  /** Stringified array */
  scopes: string;
}

const call = () => client.get<ITenantDetailsResponse>(auth.tenantDetails);

export default call;
