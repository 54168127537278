import { useState, useEffect, FC, PropsWithChildren, useMemo } from 'react';
import { Router, useRouter } from 'next/router';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Sidenav from 'examples/Sidenav';
import theme from 'assets/theme';
import themeDark from 'assets/theme-dark';
import routes from 'routes';
import { useMaterialUIController, setMiniSidenav } from 'context';
import { SnackbarProvider } from 'notistack';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import { AuthLayout } from 'domains/authentication/components/BasicLayout';
import { withAuthentication } from 'hooks';
import Footer from 'components/footer';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';

NProgress.configure({
  showSpinner: true
});

function handleRouteChange() {
  NProgress.start();
}

function handleRouteChanged() {
  NProgress.done();
}

export type TLayoutOptions = 'app' | 'auth' | 'none';

// const noSidenavList = ['login', 'essenza'];

const AppLayoutWrapper: FC = ({ children }) => {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };
  return (
    <>
      <Sidenav
        color={sidenavColor}
        brandName="Bamboo Admin"
        routes={routes}
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
      />
      <DashboardLayout>
        <DashboardNavbar />
        {children}
        <Footer />
      </DashboardLayout>
    </>
  );
};

const LayoutWrapper = ({ children, layout }: PropsWithChildren<{ layout: TLayoutOptions }>) => {
  const RenderLayout = useMemo(() => {
    if (layout === 'app') return withAuthentication(AppLayoutWrapper);
    return AuthLayout;
  }, [layout]);

  return <>{layout !== 'none' ? <RenderLayout>{children}</RenderLayout> : children}</>;
};

const App: FC<{ layout: TLayoutOptions }> = ({ children, layout }) => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const { pathname } = useRouter();

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  useEffect(() => {
    Router.events.on('routeChangeStart', handleRouteChange);
    Router.events.on('routeChangeComplete', handleRouteChanged);
    return () => {
      Router.events.off('routeChangeStart', handleRouteChange);
      Router.events.off('routeChangeComplete', handleRouteChanged);
    };
  }, []);

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <SnackbarProvider
        dense
        preventDuplicate
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'top'
        }}
      >
        <CssBaseline />

        <LayoutWrapper {...{ layout }}>{children}</LayoutWrapper>
      </SnackbarProvider>
    </ThemeProvider>
  );
};

export default App;
