import { AxiosError } from 'axios';
import { makeObservable, observable, flow, action, runInAction } from 'mobx';
import getStat, { IStatReposnse } from 'requests/dashboard';

interface ILoadingState {
  stat: boolean;
}

interface IErrorState {
  stat: string;
}

const formatToFloat = (value: string, decimalPlace: number = 2) => {
  const v = parseFloat(value);
  return Number.isNaN(v) ? parseFloat('0').toFixed(decimalPlace) : v.toFixed(decimalPlace);
};

export class DashboardImpl {
  stat: IStatReposnse = {
    deposit: '',
    revenue: '',
    trade: '',
    withdrawal: ''
  };

  isLoading: ILoadingState = {
    stat: false
  };

  errors: IErrorState = {
    stat: ''
  };

  constructor() {
    makeObservable(this, {
      stat: observable,
      isLoading: observable,
      errors: observable,
      fetchStat: flow.bound,
      setReqError: action.bound
    });
  }

  setReqError(err: AxiosError, state: string = 'error') {
    if (err.response.status !== 401) {
      this[state] = err;
      setTimeout(() => {
        this[state] = {};
      }, 10000);
    }
  }

  *fetchStat() {
    try {
      const { data } = (yield getStat()) as { data: IStatReposnse };
      this.stat = {
        deposit: formatToFloat(data.deposit),
        revenue: formatToFloat(data.revenue),
        trade: formatToFloat(data.trade),
        withdrawal: formatToFloat(data.withdrawal)
      };
    } catch (e) {
      const error = e as AxiosError;
      this.errors.stat = error.response?.data?.message || error.message;
      setTimeout(() => {
        runInAction(() => {
          this.errors.stat = '';
        });
      }, 5000);
    }
  }
}

const DashboardStore = new DashboardImpl();

export default DashboardStore;
