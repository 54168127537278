import { getDateRangeWithLabel } from './dateRange';

/**
 * This can be used to format amount in Naira properly
 * @example
 * currency.format(parseFloat('14304736.75')) // output ₦14,304,736.75
 */
export const currency = (curr: TCurrencyArgs = 'NGN') =>
  new Intl.NumberFormat(`en-${curr === 'NGN' ? 'NG' : 'US'}`, {
    style: 'currency',
    currency: curr,
    minimumFractionDigits: 2,
    currencyDisplay: 'symbol'
  });

type TCurrencyArgs = 'NGN' | 'USD';

export const ISSERVER = typeof window === 'undefined';

export { default as useLocalStorage } from './useLocalStorage';
export { default as RSAEncrypt } from './RSA';

export function capitalizeFirstLetter(string: string) {
  const words = string.toLowerCase().split(/\s+/);
  const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1));
  return capitalizedWords.join(' ');
}
export { getDateRangeWithLabel };
