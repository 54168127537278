import { AxiosError } from 'axios';
import { rangeData } from 'constants/dateRange';
import addDays from 'date-fns/addDays';
import addMonths from 'date-fns/addMonths';
import addWeeks from 'date-fns/addWeeks';
import format from 'date-fns/format';
import { action, computed, flow, observable, runInAction, makeObservable } from 'mobx';
import fetchDeposit, { getSummaryStat, IDeposit, IDepositSummaryStat } from 'requests/deposit';

export const depositKeyToLabelMap = {
  inserted_at: 'Date',
  id: 'Tx ID',
  full_name: 'Customer',
  dollar_amount: 'Amount',
  exchange_rate: 'Rate',
  dollar_processing_fee: 'Processing Fee',
  status: 'Status',
  deposit_type: 'Deposit Type',
  reference: 'Reference',
  dollar_instant_deposit_fee: 'Instant Deposit Fee',
  user_id: 'User ID'
};

export interface ITableFormattedDeposit {
  id: string;
  status: string;
  inserted_at: string;
  dollar_amount: string;
  exchange_rate: string;
  deposit_type: string;
  dollar_instant_deposit_fee: string;
  dollar_processing_fee: string;
  reference: string;
  user_id: string;
  full_name: string;
}
export class DepositStoreImpl {
  deposits: IDeposit[] = [];

  fetching: boolean = false;

  error: Partial<Error> = {};

  meta: Partial<IPaginatedMeta> = {
    currentPage: 0,
    itemCount: 0
  };

  summaryStat: IDepositSummaryStat = {
    data: [],
    range: {
      end_date: 0,
      start_date: 0
    },
    stat: 'days'
  };

  loading: Partial<{
    deposit: boolean;
    summaryStat: boolean;
  }> = {};

  tableConfig: ISetDateRangeCBArgs & ITableConfig = {
    endDate: rangeData.find((item) => item.label === 'Last 7 Days').endDate,
    startDate: rangeData.find((item) => item.label === 'Last 7 Days').startDate,
    buttonLabel: 'Last 7 Days',
    reset: false
  };

  errors: Partial<{
    summaryStat: Partial<Error>;
  }> = {};

  constructor() {
    // makeAutoObservable(this);
    makeObservable(this, {
      deposits: observable,
      fetching: observable,
      error: observable,
      errors: observable,
      meta: observable,
      summaryStat: observable,
      tableConfig: observable,
      fetchDeposit: action.bound,
      setReqError: action.bound,
      setRange: action.bound,
      toggleReset: action.bound,
      fetchStat: flow.bound,
      getDepositView: computed,
      calculatedDepositSum: computed,
      chartData: computed
    });
  }

  setRange(config: ISetDateRangeCBArgs) {
    this.tableConfig = { ...config, reset: true };
    this.deposits = [];
    this.fetchStat({ start_date: config.startDate.valueOf(), end_date: config.endDate.valueOf() });
  }

  toggleReset(reset: boolean) {
    this.tableConfig.reset = reset;
  }

  setReqError(err: AxiosError, state: string = 'error') {
    if (err.response?.status !== 401) {
      this[state] = err;
      setTimeout(() => {
        this[state] = {};
      }, 10000);
    }
  }

  fetchDeposit({ limit, page, end_date, start_date }: Partial<IPaginationConfig> = {}) {
    runInAction(() => {
      this.fetching = true;
    });
    fetchDeposit(page, limit, start_date, end_date)
      .then(({ data }) => {
        runInAction(() => {
          this.deposits = data.items;
          this.meta = data.meta;
        });
      })
      .catch((error) => {
        this.setReqError(error);
      })
      .finally(() => {
        runInAction(() => {
          this.fetching = false;
        });
      });
  }

  *fetchStat({ start_date, end_date }: Partial<IPaginationConfig> = {}) {
    this.loading.summaryStat = true;
    try {
      const { data } = yield getSummaryStat(start_date, end_date);
      this.summaryStat = data;
      this.loading.summaryStat = false;
    } catch (error) {
      this.setReqError(error, 'errors.summaryStat');
    }
  }

  get getDepositView() {
    return this.deposits.map<ITableFormattedDeposit>((deposit) => ({
      id: deposit.id,
      full_name: deposit.user?.identity_document?.full_name || 'Not available',
      deposit_type: deposit.deposit_type,
      dollar_amount: deposit.dollar_amount,
      dollar_instant_deposit_fee: deposit.dollar_instant_deposit_fee,
      dollar_processing_fee: deposit.dollar_processing_fee,
      exchange_rate: deposit.exchange_rate,
      inserted_at: deposit.inserted_at,
      reference: deposit.reference,
      status: deposit.status.match(/Settlemented/i)
        ? 'settled'
        : deposit.status.match(/Deleted/i)
        ? 'cancelled'
        : deposit.status,
      user_id: deposit.user_id
    }));
  }

  get calculatedDepositSum() {
    return this.summaryStat.data.reduce((prev, currVal) => {
      if (!currVal.sum) return prev;
      return parseFloat(currVal.sum) + prev;
    }, 0);
  }

  get chartData(): IChartData {
    const statData = this.summaryStat;
    if (statData.stat === 'days') {
      const res = statData.data.map<IChartData['data'][0]>((day) => ({
        label: format(addDays(statData.range.start_date, day.id - 1), 'd MMM'),
        value: Number(day.sum).toFixed(2)
      }));
      return {
        label: `Summary of ${format(statData.range.start_date, 'PPP')} ~ ${format(
          statData.range.end_date,
          'PPP'
        )}`,
        data: res
      };
    }
    if (statData.stat === 'months') {
      const res = statData.data.map<IChartData['data'][0]>((month) => ({
        label: format(addMonths(statData.range.start_date, month.id - 1), 'MMM'),
        value: Number(month.sum).toFixed(2)
      }));
      return {
        label: `Summary of ${format(statData.range.start_date, 'PPP')} ~ ${format(
          statData.range.end_date,
          'PPP'
        )}`,
        data: res
      };
    }
    if (statData.stat === 'weeks') {
      const res = statData.data.map<IChartData['data'][0]>((weeks) => ({
        label: format(addWeeks(statData.range.start_date, weeks.id - 1), "wo 'week'"),
        value: Number(weeks.sum).toFixed(2)
      }));
      return {
        label: `Summary of ${format(statData.range.start_date, 'PPP')} ~ ${format(
          statData.range.end_date,
          'PPP'
        )}`,
        data: res
      };
    }
    return {
      data: [],
      label: ''
    };
  }
}

const DepositStore = new DepositStoreImpl();

export default DepositStore;
