import { AxiosError } from 'axios';
import { makeObservable, observable, action, computed, flow } from 'mobx';
import fetchUser, {
  getUsersCount,
  IUser,
  IUsersStatResponse,
  getAllUsersStat
} from 'requests/users';
import parse from 'date-fns/parse';

export const userKeyToLabelMap = {
  inserted_at: 'Created at',
  id: 'User ID',
  full_name: 'Full Name',
  phone_number: 'Phone Number',
  email: 'Email',
  user_holdings: 'Portfolio',
  status: 'Status',
  updated_at: 'Updated at',
  verified: 'Verified',
  funded_account: 'Account Funded',
  date_of_birth: 'DOB',
  address: 'Address',
  kyc_status: 'KYC Status'
};
export interface ITableformattedUser {
  id: string;
  phone_number: string;
  verified: boolean;
  inserted_at: string;
  updated_at: string;
  funded_account: boolean;
  user_holdings: Omit<IStockHolding, 'logo_url'>[];
  email: string; // > users_basic_info.email
  date_of_birth: string; // > identity_document?.date_of_birth || users_basic_info.date_of_birth
  full_name: string; // > identity_document?.full_name || users_basic_info.name & users_basic_info.surname
  address: string;
  status: 'active' | 'inactive';
}

export class UserStoreImpl {
  users: IUser[] = [];

  usersStat: IUsersStatResponse = {
    active_users: 0,
    inactive_users: 0,
    users_count: 0
  };

  isLoading: {
    usersStat: boolean;
  } = {
    usersStat: false
  };

  usersCount: number = 0;

  fetchingUserCount: boolean = false;

  fetching: boolean = false;

  error: Partial<Error> = {};

  meta: Partial<IPaginatedMeta> = {
    currentPage: 0,
    itemCount: 0
  };

  constructor() {
    makeObservable(this, {
      users: observable,
      fetching: observable,
      error: observable,
      meta: observable,
      usersCount: observable,
      fetchingUserCount: observable,
      usersStat: observable,
      isLoading: observable,

      fetchUser: flow.bound,
      getUserCount: flow.bound,
      fetchUserStat: flow.bound,
      setReqError: action.bound,
      getUserView: computed
    });
  }

  setReqError(err: AxiosError) {
    if (err.response?.status !== 401) {
      this.error = err;
      setTimeout(() => {
        this.error = {};
      }, 10000);
    }
  }

  *fetchUser({ limit, page, end_date, start_date }: Partial<IPaginationConfig>) {
    this.fetching = true;
    try {
      const { data } = yield fetchUser({ page, limit, end_date, start_date });
      this.users = data.items;
      this.meta = data.meta;
    } catch (error) {
      this.setReqError(error);
    } finally {
      this.fetching = false;
    }
  }

  *getUserCount() {
    this.fetchingUserCount = true;
    try {
      const { data } = yield getUsersCount();
      this.usersCount = data;
    } catch (error) {
      this.setReqError(error);
    } finally {
      this.fetchingUserCount = false;
    }
  }

  *fetchUserStat() {
    this.isLoading.usersStat = true;
    try {
      const { data } = (yield getAllUsersStat()) as { data: IUsersStatResponse };
      this.usersStat = data;
    } catch (error) {
      this.setReqError(error);
    } finally {
      this.isLoading.usersStat = false;
    }
  }

  get getUserView() {
    return this.users.map<ITableformattedUser>((user) => ({
      id: user.id,
      phone_number: user.phone_number,
      address: user.identity_document?.address || '',
      date_of_birth: user.identity_document?.date_of_birth
        ? parse(user.identity_document.date_of_birth, 'dd-MM-yyyy', new Date()).toISOString()
        : `${user.users_basic_info.date_of_birth}`,
      email: user.users_basic_info.email,
      full_name:
        user.identity_document?.full_name ||
        `${user.users_basic_info.name} ${user.users_basic_info.surname}`,
      funded_account: user.funded_account,
      inserted_at: user.inserted_at,
      updated_at: user.updated_at,
      user_holdings: user.user_holdings,
      verified: user.verified || Boolean(user.identity_document),
      status: user.identity_document && user.funded_account ? 'active' : 'inactive'
    }));
  }
}

const UserStore = new UserStoreImpl();

export default UserStore;
