import { getTrades, getTradeSummaryStat, lifeTimeTradeSum } from 'constants/api';
import { rangeData } from 'constants/dateRange';
import client from 'requests';

const range = rangeData.find((item) => item.label === 'Last 7 Days');

const call = (
  page = 1,
  limit = 25,
  start_date = range.startDate.valueOf(),
  end_date = range.endDate.valueOf()
) =>
  client.get<IPaginatedEndpointResp<ITradesHistory>>(
    getTrades({ page, limit, end_date, start_date })
  );

export const getSummaryStat = (
  start_date = range.startDate.valueOf(),
  end_date = range.endDate.valueOf()
) => client.get<ITradesSummaryStat>(getTradeSummaryStat({ end_date, start_date }));

export const getLifeTimeTradeSum = () => client.get<{ sum: string }>(lifeTimeTradeSum);

export interface ITradesSummaryStat {
  range: {
    start_date: number;
    end_date: number;
  };
  returnedSum: {
    buy_sum: number;
    sell_sum: number;
  };
}

export default call;

export interface ITradesHistory {
  id: string;
  inserted_at: string;
  price_per_share: string;
  side: 'buy' | 'sell';
  stock_symbol: string;
  transaction_value: string;
  type: string;
  quantity: string;
  dollar_fee: string;
  status: TTradeStatus;
  user_currency_fee: string;
  user_currency_transaction_value: string;
  user_currency_price_per_share: string;
  user?: IUserShortDetails;
  naira_fee: string;
  user_id: string;

  // maybe removed
  dw_order_id?: 'JC.5f5a368b-a222-466b-84ae-35999607d531';
  updated_at?: '2022-03-03T08:51:32.000Z';
  api_consumer_id?: 1;
}
